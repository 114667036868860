import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"

const SuccessPage = () => {
  return (
    <Layout pageTitle="Success">
      <div className="nomal-page">
        <h1>送信ありがとうございました。</h1>
        <p>
          <br />
          送信は正常に完了しました。
          <br />
          <br />
          <br />
          <br />
          <Link to="/" className="underline">ホームに戻る</Link>.<br/>
        </p>
      </div>
    </Layout>
  )
}

export default SuccessPage
